<script setup lang="ts">
import { type Content } from "@prismicio/client";

const props = defineProps(getSliceComponentProps<Content.HeroSlice>(["slice", "index", "slices", "context"]));
const primary = computed(() => props.slice.primary);
const { getBackgroundStyles } = useBackgroundStyles();
</script>

<template>
  <section
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    class="mx-auto flex -md:flex-col items-center gap-10 -md:gap-0 justify-center max-w-[1328px] -md:bg-gray-50"
  >
    <div class="flex-1 shrink-0 px-6 py-20 -md:py-10 -md:w-full">
      <RichText
        :text="primary.title"
        class="text-black font-bold mb-8 -md:mb-10 leading-[64px] text-5xl -lg:text-4xl -lg:leading-[48px] [&_strong]:text-primary -md:text-3xl -md:leading-[48px] -md:text-center"
      />
      <RichText
        :text="primary.description"
        class="text-base leading-[28px] -md:hidden -md:text-base text-gray-500 mb-14 -md:text-center"
      />
      <ZSearchAddress :button-text="primary.findPropertyButton" :placeholder="primary.searchPlaceholder" />

      <div class="flex gap-2 mt-2">
        <span class="text-gray-500 text-lg -md:text-base">
          {{ primary.subDescription }}
        </span>
        <PLink
          :link-object="primary.postProperty"
          class="text-primary-500 underline text-lg -md:text-base hover:underline hover:text-primary-300"
        >
          {{ primary.postPropertyText }}
        </PLink>
      </div>
    </div>

    <div
      class="flex-1 -md:pb-[300px] -md:w-full h-[600px] bg-cover bg-no-repeat bg-center"
      :style="{
        backgroundImage: `${getBackgroundStyles(primary.background, 'sm:100vw md:120vw').backgroundImage}`,
      }"
    ></div>
  </section>
</template>
