export const useBackgroundStyles = () => {
  const $img = useImage();
  const getBackgroundStyles = (imageObject: any, sizes: string = "lg:110vw") => {
    if (!imageObject?.url) return {};

    const imgUrl = $img.getSizes(imageObject.url, {
      sizes: sizes || "lg:110vw",
      modifiers: {
        quality: 80,
        format: "webp",
      },
    });
    return {
      backgroundImage: `url(${imgUrl.src})`,
    };
  };

  return {
    getBackgroundStyles,
  };
};
